import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
  Button,
  Accordion,
  Card,
} from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/Hepatofelin-mobile.jpg"
import LuteinComplexTablet from "./../../images/Hepatofelin-tablet.jpg"
import LuteinComplexDesktop from "./../../images/Hepatofelin-desktop.jpg"
import LuteinComplexXL from "./../../images/Hepatofelin-desktop-xl.jpg"
import Feature1 from "./../../images/hep-liver-img.jpg"
import Feature2 from "./../../images/hep-milk-thistle.jpg"
import Feature3 from "./../../images/hep-liver-prevention.jpg"
import Feature4 from "./../../images/hep-artichoke.jpg"
import Feature5 from "./../../images/omega-3-acids.jpg"
import FeatureLicaps from "./../../images/LICAPS-GUARANTEE.jpg"

const HepatofelinPageEn = () => (
  <Layout backButton enSite pageInfo={{ pageName: "Hepatofelin-en" }}>
    <Seo title="Hepatofelin - Optimal care for the liver" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/en">HOME</Link>
            </li>
            <Breadcrumb.Item active>HEPATOFELIN</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Hepatofelin"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
        <Col xs={6} className="d-none d-lg-block large-heading">
          <h5 className="text-uppercase font-light letter-space-wide">
            Hepatofelin
          </h5>
          <h1 className="display-4 font-light">
            Optimal liver
            <br /> support
          </h1>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col sm={12} className="d-lg-none">
          <h4 className="text-uppercase mb-3 letter-space-wide">Hepatofelin</h4>
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Optimal care
            <br />
            for the liver
          </h1>
        </Col>
        <Col sm={12} md={6} className="mb-4">
          <h5 className="subhead font-light">
            Liquid formula for fast absorption of the most useful substances for
            the liver - silymarin, artichoke and dandelion extracts, supported
            by flaxseed and soybean oil.
          </h5>
        </Col>
        <Col sm={12} md={6}>
          <Col className="mb-4 p-0">
            <div>
              <a
                href="https://bemore.shop/ena/hepatofelin-envh1003"
                target="blank"
                rel="nofollow"
                className="text-decoration-none"
              >
                <Button
                  variant="primary"
                  className="buy-button mx-auto d-block"
                >
                  Buy online at <strong>BeMore.shop</strong>
                </Button>
              </a>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <p>Available in pharmacies across the country</p>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <ul>
                <li>solvent free</li>
                <li>preservatives free</li>
                <li>gluten free</li>
                <li>sugar free</li>
                <li>GMO-free</li>
              </ul>
            </div>
          </Col>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">
            The liver – our body's “chemical laboratory”
          </h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature1}
            alt="The liver – our body's “chemical laboratory”"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Hepatofelin is a natural product that supports liver function and
            contains the optimal dose of milk thistle extract (silymarin),
            artichoke, dandelion, soybean and linseed oil.
          </p>
          <p>
            The liver processes and breaks down the substances we take in.
            Alcohol abuse, smoking, medications, and some viruses can cause
            liver damage. Herbal therapy is often the solution to many of the
            observed problems, and it is also recommended for prevention.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Free your body of toxins</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature2}
            alt="Free your body of toxins"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6}>
          <p>
            Some substances and medicines, such as paracetamol and
            acetaminophen, put a strain on the liver and should not be overused.
          </p>
          <p>
            Silymarin (milk thistle extract) is the main ingredient in
            Hepatofelin and is one of the most effective natural products for
            maintaining liver function and maintaining the activity of liver
            cells. Its effectiveness has been supported by various studies and
            it has been used for centuries in various liver conditions.
          </p>
          <p>
            Artichoke is especially rich in cynarine - a powerful antioxidant
            that supports the detoxifying functions of the liver and urinary
            system.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Prevention and support</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature3}
            alt="Prevention and support"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            The active ingredients in Hepatofelin support liver function and
            hepatocytes, help counteract the side effects of medication, alcohol
            and cigarette abuse. They support the normalization of liver enzyme
            levels. Available data show that the intake of Hepatofelin increases
            the regenerative capacity of liver cells by 23% after the body has
            accumulated "pests" due to the use of alcohol, nicotine, drugs and
            more.
          </p>
          <p>
            The effectiveness of the substances in Hepatofelin is supported by
            the fact that they are in a dissolved state, which ensures their
            rapid absorption by the body.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">
            Accelerated metabolism and improved digestion
          </h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature4}
            alt="Accelerated metabolism and improved digestion"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6}>
          <p>
            The action of the active ingredients on the liver and bile affects
            the metabolism and supports digestion.
          </p>
          <p>
            Artichoke enhances bile secretion and diuresis, and at the same time
            stimulates cholesterol metabolism. Dandelion contains bioactive
            substances that support the secretion and tone of the digestive
            system. It supports the activity of the liver, diuretic function of
            the kidneys and urinary tract, as well as the gastrointestinal
            tract.
          </p>
          <p>
            Hepatofelin also contains soybean and flaxseed oil, both of which
            have beneficial effects on the metabolism of fats in the blood, the
            regulation of serum cholesterol and support of the cardiovascular
            system.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">
            Enriched with Omega-3 fatty acids for a healthy body
          </h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature5}
            alt="Enriched with Omega-3 fatty acids for a healthy body"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Hepatofelin also contains Omega-3 fatty acids in the form of linseed
            oil. They are necessary for the normal functioning of cells and help
            protect the body from developing a number of diseases. They also
            support the cardiovascular system and help maintain healthy
            cholesterol levels. Omega-3 fatty acids are a vital component that
            the human body cannot produce on its own.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Guarantee for quality and safety</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={FeatureLicaps}
            alt="Guarantee for quality and safety"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6}>
          <p>
            Hepatofelin capsules are manufactured at Capsugel's plants in
            Colmar, France, using the patented technology for using liquid plant
            extracts in hard capsules.
          </p>
          <p>
            This technology eliminates the heat treatment of substances, which
            allows to preserve their useful properties and ensures rapid and
            complete absorption by the body. Compared to solid forms such as
            tablets, the ingredients in Hepatofelin capsules are released very
            quickly because they are pre-dissolved. They are completely natural
            and free of solvents, preservatives, gluten, sugar and GMOs.
          </p>
          <p>
            Read more in the <Link to="/en/technology">“Technology”</Link>{" "}
            section.
          </p>
        </Col>
      </Row>

      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Additional information</h5>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col>
          <Accordion defaultActiveKey="0" className="acc-style">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="pointer"
              >
                Contents
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    <strong>Milk thistle</strong> – dry seed extract, 800 mg / g
                    silymarin <br />
                    (Silybum marianum – extr. of seed, 800 mg/total silymarin)
                    100 mg
                  </p>
                  <p>
                    <strong>Artichoke</strong> – dry extract of overground parts
                    (Cynara scolymus L. – extract of overground parts) 40 mg
                  </p>
                  <p>
                    <strong>Dandelion</strong> – dry root extract (Taraxacum
                    officinale – extr. of roots) 30.0 mg
                  </p>
                  <p>
                    <strong>Linseed virgin oil</strong> 300.0 mg
                  </p>
                  <p>
                    <strong>Refined soybean oil</strong> 24 mg
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="pointer"
              >
                Intake
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>
                    Take 2 capsules Hepatofelin daily after meals, no less than
                    3 months, without interruption.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="pointer"
              >
                Contraindications
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    None identified. Hepatofelin is a dietary supplement and
                    does not cause irritation and hypersensitivity, and can be
                    taken for a long period. Hepatofelin can be taken alone or
                    in combination with medication prescribed by your doctor.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="pointer"
              >
                Effects
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Hepatofelin contains the optimal dose of silymarin, which
                    has a scientifically proven effect on liver health:
                  </p>
                  <p>
                    Prevention and treatment of chronic hepatitis and liver
                    inflammation: Several different studies have shown that oral
                    silymarin has a beneficial effect on liver enzymes in
                    various types of hepatitis (chronic viruses or
                    alcohol-induced). Prevention and treatment of cirrhosis of
                    the liver, alcohol intoxication: European studies in the
                    treatment of cirrhosis with oral silymarin have shown good
                    results in many of the patients studied, some of whom have
                    taken the supplement for five years. The damage from alcohol
                    intoxication, a symptom of which is the hangover, reacts
                    well in a large portion of patients who receive silymarin
                    therapy within a few weeks. Liver detoxification and
                    regeneration: There is evidence that the active flavonoids
                    in silymarin help liver cells fight various toxins. Data has
                    been collected on the improvement of liver function due to
                    silymarin in patients treated with various liver-damaging
                    drugs.
                  </p>
                  <p>How do the active ingredients in Hepatofelin work?</p>
                  <p>
                    Milk thistle is one of the most effective natural products
                    for improving liver function and maintaining the activity of
                    liver cells. It is used to strengthen liver activity in
                    conditions caused by toxic effects of alcohol, drugs,
                    hepatitis, poisons and more. Supports the activity of
                    hepatocytes (liver cells) and increases their resistance.
                    Supports digestive activity by promoting the formation and
                    secretion of bile, supports metabolism. The most valuable
                    ingredient in milk thistle extract is the flavonoid
                    silymarin. The positive effect of silymarin on liver cells
                    is the result of parallel interaction with receptors for
                    relevant toxins in the hepatocyte membrane. Silymarin binds
                    to free radicals and reduces the adverse effects of toxic
                    substances such as alcohol, certain drugs and others.
                    Supports the regenerative functions of liver cells by
                    stimulating the synthesis of proteins and phospholipids in
                    hepatocytes.
                  </p>
                  <p>
                    Artichoke. With the bioactive substances contained in it, it
                    supports the functions of the liver cells, especially when
                    exposed to various toxins. ARTICHOKE is especially rich in
                    cynarin - a bioflavonoid, a powerful antioxidant that
                    supports the detoxifying functions of the liver and urinary
                    system. Artichoke enhances bile secretion and diuresis,
                    stimulates cholesterol metabolism.
                  </p>
                  <p>
                    Dandelion contains biologically active substances that
                    support the secretion and tone of the digestive system and
                    bile secretion. It is used to support the activity of the
                    liver, the diuretic activity of the kidneys and urinary
                    tract and the gastrointestinal tract. Increases the
                    protective functions of the body.
                  </p>
                  <p>
                    Soybean oil is known for its rich lecithin and phospholipid
                    content, both of which help metabolize fats in the blood,
                    regulate serum cholesterol levels and support the activity
                    of blood vessel walls.
                  </p>
                  <p>
                    Flaxseed oil is rich in long-chain polyunsaturated fatty
                    acids: omega-3, omega-6 and omega-9. The intake of flaxseed
                    oil supports the activity of the cardiovascular system,
                    helps maintain healthy blood sugar levels.
                  </p>
                  <p>Causes for elevated liver enzymes</p>
                  <p>
                    The liver is an organ that plays an important role in many
                    physiological processes in the body. There are many factors
                    that threaten its proper functioning. “Signals” for
                    something wrong is an increase in liver enzymes, which can
                    range from mild infection to cancer.
                  </p>
                  <p>
                    The liver is the largest organ in the human body. It helps
                    with digestion, metabolism, protein synthesis and overall
                    detoxification. But liver enzymes can be affected by a
                    number of conditions in other different organs. Changes in
                    liver enzyme levels can also be due to bile problems, as
                    well as more serious causes such as cancer, cirrhosis or
                    heart failure.
                  </p>
                  <p>Types of liver enzymes</p>
                  <p>
                    These are the types of enzymes that could be elevated, along
                    with conditions that can affect their levels in laboratory
                    tests.
                  </p>
                  <p>
                    ALT (alanine aminotransferase): When liver cells are damaged
                    for some reason, the level of the enzyme rises in the blood.
                    If there is no trauma, the cause of the high levels may be
                    an infection, such as viral hepatitis, or taking an overdose
                    of medication.
                  </p>
                  <p>
                    AST (aspartate aminotransferase): This enzyme is also
                    increased in cases of liver damage. However, it is not
                    specific to the liver, and may be elevated in kidney, brain,
                    heart or skeletal muscle problems. If the ASTs are higher
                    than normal and the ALT values are higher, then the problem
                    is most likely not in the liver.
                  </p>
                  <p>
                    AF (alkaline phosphatase): High AF is normal in children and
                    pregnant women. In other patients, it may show a problem
                    with bile, inflammation, cirrhosis, hepatitis or others.
                    Also, some medications can increase the levels of AF in the
                    blood.
                  </p>
                  <p>
                    GGT (gamma-glutamyl transferase): Elevated levels may
                    indicate hepatitis, cirrhosis or bile problems. It could
                    also be elevated by heart failure, alcohol abuse, and some
                    medications.
                  </p>
                  <p>
                    TB (total bilirubin) and DB (direct bilirubin): High levels
                    of bilirubin can cause jaundice. When total bilirubin is
                    elevated, direct bilirubin can help identify the problem. If
                    the direct is normal, the cause may be cirrhosis or
                    hepatitis. And if both general and direct are elevated, it's
                    most likely a biliary problem.
                  </p>
                  <p>
                    <a
                      href="http://vitaherb.bg/leaflets/Hepatofelin-Quality-Certificate.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Product quality certificate
                    </a>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default HepatofelinPageEn
